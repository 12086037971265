$white: rgb(255, 255, 255);
$white_ish: rgb(215, 215, 215);
$white_ish2: rgb(254, 254, 254);
$blue: rgb(14, 151, 213);
$blue_hover: rgb(12, 135, 191);
$dark: rgb(37, 37, 37);
$light_dark: rgb(63, 63, 63);
$gray: rgb(235, 235, 235);
$light_gray: rgb(247, 247, 247);
$off_gray: rgb(101, 100, 100);
$green: rgb(55, 194, 137);
$green_hover: rgb(49, 174, 123);
$pink: rgb(214,50,101);
$pink_hover: rgb(204,44,96);

a {
    color: $blue;
    &:hover {
        color: $blue_hover !important;
    }
}
svg {
    color: $blue;
}
.bg-dark {
    background-color: rgb(51, 51, 52) !important;
}
.bg-light-dark {
    background-color: $light_dark !important;
}
.bg-gray {
    background-color: $gray !important;
}
.bg-light-gray {
    background-color: $light_gray !important;
}
.bg-gray-mobile {
    @media screen and (max-width: 960px) {
        background-color: $gray !important;
    }
}
.text-white {
    color: $white;
}
.text-blue {
    color: $blue;
}
.text-off-gray {
    color: $off_gray;
}
.text-light-gray {
    color: $light_gray;
}
.gray-link {
    color: rgba(101, 100, 100, 0.6) !important;
    &:hover {
        color: rgba(101, 100, 100, 1) !important;
    }
}
.bs-link {
    color: #212529 !important;
    &:hover {
        color: #373a3e !important;
    }
}
