.development-details-page {
    .details .price {
        font-size: 30px;
    }
    .details h1 b {
        font-size: 30px;
        @media screen and (max-width: 760px) {
            font-size: 24px;
        }
    }
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background-color: $white;
}
.breadcrumb-item+.breadcrumb-item::before {
    color: #6c757d;
    content: ">";
}
.pagination {
    margin-bottom: 0;
}
img[src="img/right-arrow-blue-min.png"] {
    width: 18px;
}
.s55px {
    font-size: 55px;
}
.s40px {
    font-size: 40px;
}
.s35px {
    font-size: 35px;
}
.s30px {
    font-size: 30px;
}
.s29px {
    font-size: 29px;
}
.sm26px {
    @media screen and (max-width: 960px) {
        font-size: 26px !important;
    }
}
.s25px {
    font-size: 25px !important;
}
.s22px {
    font-size: 22px !important;
}
.s20px {
    font-size: 20px !important;
}
.s18px {
    font-size: 18px !important;
}
.s17px {
    font-size: 17px !important;
}
.s15px {
    font-size: 15px !important;
}
.s13px {
    font-size: 13px !important;
}
.o-6 {
    opacity: 0.6;
}
.quick-link {
    color: $dark;
    font-weight: 500;
}
.icon-wrap {
    width: 30px;
    text-align: center;
    img[src="img/heart-icon-large-min.png"] {
        width: 92px;
        height: 81px;
    }
    img[src="img/bulb-icon-large-min.png"] {
        width: 67px;
        height: 103px;
    }
    img[src="img/star-icon-large-min.png"] {
        width: 78px;
        height: 102px;
    }
    img[src="img/home-icon-large-min.png"] {
        width: 89px;
        height: 83px;
    }
}
body {
    background-color: $white_ish2;
    overflow-x: hidden;
}
.sub {
    color: rgb(101, 100, 100);
    @media screen and (min-width: 1000px) {
        font-size: 25px !important;
    }
}
.search-btn {
    padding: 0 !important;
    height: 50px;
    width: 50px;
    background-image: url('/assets/img/search-icon-min.png');
    background-repeat: no-repeat;
    background-position: center center;
    border-top-left-radius: none;
    border-top-right-radius: none;
}
.custom-select {
    padding: 15px 40px 15px 25px !important;
    background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
    @media screen and (max-width: 960px) {
        padding: 15px 40px 15px 15px !important;
        background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
    }
    &.normal {
        padding: 10px 40px 10px 15px !important;
    }
}
.title-plus-blue {
    font-size: 40px;
    small {
        color: $blue;
        font-size: 17.373px;
        font-weight: 500;
        text-transform: uppercase;
    }
    @media screen and (max-width: 960px) {
        font-size: 31.25px;
    }
}
.title-plus-underline {
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(172, 172, 172, 0.5);
}
.title-plus-underline-light {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(101, 100, 100, 0.2);
}
.details-header {
    font-size: 20px;
    color: rgba(101, 100, 100, 0.8);
    b {
        font-size: 29px;
        font-weight: 500;
        color: $dark;
    }
}
.details-price {
    font-size: 22px;
    b {
        font-size: 32px;
    }
}
.blue-bullet {
    position: relative;
    padding-left: 20px;
    &:after {
        position: absolute;
        display: block;
        content: ' ';
        height: 4px;
        width: 4px;
        left: 0;
        top: 50%;
        border-radius: 50%;
        background-color: $blue;
    }
}
.line-through {
    position: relative;
    span {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        color: #979797;
        background-color: $white;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }
    &:before {
        position: absolute;
        display: block;
        content: ' ';
        height: 1px;
        width: 100%;
        top: 11px;
        right: 0;
        left: 0;
        background-color: #dee2e6;
        z-index: 100;
    }
}
h2 b {
    font-size: 125%;
}
.form-control {
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    height: auto;
    background-color: $white;
    font-weight: 300;
}
.container {
    padding-left: 50px !important;
    padding-right: 50px !important;
    max-width: 100% !important;
    @media (min-width: 1500px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
        max-width: 1450px !important;
    }
    @media (max-width: 960px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
        max-width: 100% !important;
    }
}
.container-none {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.about-image {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
    background: url('/assets/img/about-mobile-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.areas {
    padding: 80px 0;
    @media screen and (max-width: 960px) {
        padding: 50px 0;
        h2 {
            text-align: center;
        }
    }
}
.home-page .areas {
    padding: 40px 0 80px 0;
    @media screen and (max-width: 960px) {
        padding: 20px 0 30px 0;
        h2 {
            text-align: center;
        }
    }
}
.nav-tabs {
    border-bottom: none;
    .nav-link {
        padding: .25rem 1.5rem;
        border: 1px solid transparent;
        border-radius: 3px;
        color: $dark;
        font-weight: 600;
        text-transform: uppercase;
        &:hover {
            border-color: transparent;
        }
    }
    .nav-item.show .nav-link, .nav-link.active {
        border-color: $blue !important;
        border-radius: 3px;
    }
    @media screen and (max-width: 960px) {
        .nav-link {
            font-size: 0.8rem;
        }
    }
}
.logo {
    width: 140px;
    height: 31px;
    @media screen and (max-width: 960px) {
        width: 100px;
        height: 22px;
    }
}
.modal-content {
    position: relative;
    border: none;
    .m-header {
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
        padding: 2.25rem 4.25rem;
    }
    .m-body {
        padding: 0 4.25rem 2.25rem 4.25rem;
    }
    .m-body-sm {
        padding: 0 1.25rem 2.25rem 1.25rem;
    }
    .m-footer {
        margin-left: 10px;
        margin-right: 10px;
        padding: 1.45rem 4.25rem 2.25rem 4.25rem;
    }
    .modal-body {
        padding: 0;
    }
    .modal-title {
        font-size: 1.7rem;
        font-weight: bold;
    }
    .modal-sub {
        font-size: 1.15rem;
        font-weight: 300;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 100000;
    }
    img {
        margin-top: 30px;
        height: 30px;
    }
    .label {
        font-weight: bold;
        font-size: 1.25rem;
    }
    .btn-outline {
        img {
            height: 20px;
        }
    }
    @media screen and (max-width: 960px) {
        .m-header {
            padding: 2.25rem;
        }
        .m-body {
            padding: 0 2.25rem 2.25rem 2.25rem;
        }
        .m-footer {
            padding: 1.45rem 2.25rem 2.25rem 2.25rem;
        }
    }
}
.modal-dialog {
    @media screen and (max-width: 960px) {
        display: flex;
        align-items: center;
        min-height: calc(100% - 3.5rem);
    }
}
.modal-backdrop.show {
    background-color: rgb(63, 63, 63);
    opacity: .961;
}
.modal-share {
    .modal-content {
        background-color: transparent;
    }
    .modal-title {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    }
}
.modal .close-fixed {
    position: fixed !important;
    top: 15px;
    right: 25px;
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    z-index: 100000;
    &:hover {
        opacity: .75;
        color: #000;
        text-decoration: none;
    }
    img {
        height: 30px;
        width: 30px;
    }
}

#global-modal {
    .modal-content {
        border-radius: 0;
    }
}
