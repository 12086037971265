.hero-home {
    height: 500px;
    margin: 131px 0 30px 0;
    background: url('/assets/img/hero-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 3px;
    .wrap {
        padding: 0 100px;
    }
    .home-search {
        margin-bottom: 22px;
        input {
            height: 50px;
        }
        button {
            padding: 0 !important;
            height: 50px;
            width: 50px;
            background-image: url('/assets/img/search-icon-min.png');
            background-repeat: no-repeat;
            background-position: center center;
            border-top-left-radius: none;
            border-top-right-radius: none;
        }
    }
    .badge {
        padding: 10px;
        border-radius: 3px;
        font-size: 0.8rem;
        font-weight: 500;
        background-color: rgba(255, 255, 255, 0.6);
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
    h1 {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 1199px) {
        h1 {
            font-size: 1.85rem;
        }
    }
    @media screen and (max-width: 960px) {
        height: auto;
        margin: 101px 0 24px 0;
        padding: 60px 0;
        h1 {
            margin-bottom: 20px;
        }
        .wrap {
            padding: 0 25px;
        }
        .w-80 {
            width: 100% !important;
        }
    }
    @media screen and (max-width: 760px) {
        h1 {
            font-size: 2rem !important;
        }
        .w-80 {
            width: 80% !important;
        }
    }
}
.hero-large-pull-up {
    position: relative;
    margin: -370px auto 0 auto;
    @media screen and (max-width: 960px) {
        margin: -70px auto 0 auto;
    }
}
.hero {
    height: 600px;
    margin: 131px 0 0 0;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 3px;
    .with-extra {
        height: calc(100% - 72px);
    }

    &.-hero-development{
        margin: 82px 0 0;
        height: 720px;
    }

    h1 {
        margin-bottom: 30px;
        font-size: 50px;
        color: $white_ish2;
        text-shadow: 0px 3px 22.56px rgba(93, 93, 93, 0.61);
    }
    .extra {
        padding: 15px 0;
        background-color: rgba(14, 151, 213, 0.9);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        p {
            margin: 0;
            font-size: 28px;
            color: $white_ish2;
        }
    }
    .item-block {
        display: block;
        height: 100%;
        width: 100%;
    }
    .section-holder-overlay {
        opacity: 0.35;
        background-color: $dark;
        display: block;
    }
    .position-absolute-bottom{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    @media screen and (max-width: 1199px) {
        &.-hero-development{
            height: 600px;
        }
    }
    @media screen and (min-width: 1000px) {
        .wrap {
            padding: 0 100px;
        }
    }
    @media screen and (max-width: 991px) {
        &.-hero-development{
            margin: 62px 0 0;
        }
    }
    @media screen and (max-width: 960px) {
        position: relative;
        height: 500px;
        margin: 101px 0 0 0;
        .with-extra {
            height: calc(100% - 54px);
        }
        .extra p {
            font-size: 16px;
        }
        &.-hero-development{
            height: 500px;
        }
    }
}

.home-page {
    .hero-home {
        @media screen and (max-width: 960px) {
            margin: 82px 0 0 0;
        }
    }
    h1 {
        font-size: 3rem;
    }
    @media screen and (max-width: 1199px) {
        h1 {
            font-size: 2.5rem;
        }
    }
}
