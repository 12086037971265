header {
    position: relative;
}

/*-----------------------------------------
* Box
-----------------------------------------*/
.box {
    position: relative;
    margin-top: 15px;
    height: 380px;
    border-radius: 3px;
    background: url('/assets/img/areas/tenerife-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    &:after {
        position: relative;
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('/assets/img/image-overlay-min.png') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        z-index: 10;
    }
    .color-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        z-index: 100;
    }
    .details {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        z-index: 200;
        p {
            margin-bottom: 0;
        }
        .font-bold {
            font-size: 1.5rem;
        }
        .price {
            margin-top: 20px;
            font-size: 1rem;
            font-weight: 300;
            color: $white;
            b {
                font-size: 1.5rem;
            }
        }
    }
    a {
        position: absolute;
        margin: 0 auto;
        width: intrinsic;
        width: -moz-max-content;
        width: -webkit-max-content;
        bottom: -200px;
        left: 0;
        right: 0;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        z-index: 200;
    }
    &:hover {
        .color-overlay {
            background-color: rgba(50, 50, 51, 0.5);
        }
        .details {
            bottom: 120px;
        }
        a {
            bottom: 50px;
        }
    }
    @media screen and (max-width: 1240px) {
        .btn-green {
            padding: 10px 20px;
        }
    }
    @media screen and (max-width: 960px) {
        height: 300px;
        .details {
            bottom: 60px;
        }
    }
    @media screen and (max-width: 650px) {
        .btn-green {
            width: 60%;
            padding: 10px 15px;
            font-size: 0.85rem;
        }
    }
}

/*-----------------------------------------
* Vimeo Ad
-----------------------------------------*/
.vimeo {
    min-height: 567px;
    padding: 3.25rem;
    border: 1px solid $dark;
    border-radius: 3px;
    background-color: rgb(242, 158, 187);
    img {
        margin-bottom: 3rem;
    }
    p {
        font-size: 27px;
        font-weight: bold;
        color: $dark;
    }
    a {
        display: inline-block;
        width: auto;
        padding: 1rem 2rem;
        border-radius: 3px;
        background-color: $dark;
        color: $white;
        font-size: 17.432px;
        font-weight: bold;
    }
    @media screen and (min-width: 1000px) {
        p span {
            font-size: 48.423px;
        }
    }
}
.vimeo-2 {
    min-height: 387px;
    padding: 3.25rem;
    border: 1px solid $dark;
    border-radius: 3px;
    background-color: rgb(242, 158, 187);
    img {
        display: inline-block;
        height: 32px;
    }
    p {
        font-size: 27px;
        font-weight: 500;
        line-height: 1;
        color: $dark;
        span {
            font-size: 32.423px;
            font-weight: bold;
        }
    }
    a {
        display: inline-block;
        width: auto;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        background-color: $dark;
        color: $white;
        font-size: 17.432px;
        font-weight: bold;
    }
    @media screen and (min-width: 1000px) {
        p span {
            font-size: 48.423px;
        }
    }
    @media screen and (max-width: 960px) {
        .mr-auto {
            margin-right: 0 !important;
        }
        .logo-button {
            align-items: center;
            justify-content: center;
        }
    }
}
.vimeo-3 {
    @extend .vimeo-2;
    min-height: 290px;
    padding: 1.5rem;
    img {
        display: inline-block;
        height: 32px;
    }
    p {
        font-size: 27px;
        font-weight: 500;
        line-height: 1;
        color: $dark;
        span {
            font-size: 32.423px;
            font-weight: bold;
        }
    }
    a {
        display: inline-block;
        width: auto;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        background-color: $dark;
        color: $white;
        font-size: 17.432px;
        font-weight: bold;
    }
    @media screen and (min-width: 1000px) {
        p span {
            font-size: 42px;
        }
    }
    @media screen and (max-width: 960px) {
        .mr-auto {
            margin-right: 0 !important;
        }
        .logo-button {
            align-items: center;
            justify-content: center;
        }
    }
}

/*-----------------------------------------
* Cards
-----------------------------------------*/
.card {
    background-color: $white;
    box-shadow: 0px 3px 8.46px 0.54px rgba(111, 111, 111, 0.16);
    border: none;
    .card-body {
        padding: 2.25rem;
    }
    .card-body-small {
        padding: 1.75rem;
    }
    .card-date {
        font-size: 15px;
        color: rgb(101, 100, 100);
    }
    .card-title {
        font-size: 19.303px;
        color: $dark;
        &.news-title{
            font-size: 21px;
        }
        @media screen and (max-width: 1000px) {
            font-size: 19px !important;
        }
    }
    .card-text {
        font-size: 15px;
        color: $dark;

        &.news-text{
            //margin-bottom: 0;
            height: 62px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .card-link {
        font-size: 15px;
        font-weight: 500;
        color: $green;
        text-transform: uppercase;
    }
}
.agent-card {
    .card-image {
        height: 200px;
        padding: 3.5rem 2.25rem;
        img {
            max-height: 90%;
        }
    }
    .card-body {
        background-color: $light_gray;
    }
    .card-title {
        font-size: 25px;
    }
    .card-text {
        font-size: 17px;
    }
}
.form-card {
    .iti {
        width: 100%;
    }
    .iti input, .iti input[type=tel] {
        margin-bottom: 0.5rem !important;
    }
    .iti__flag-container {
        top: -0.5rem;
    }
    p {
        font-size: 13px;
        color: rgba(101, 100, 100, 0.6);
    }
    @media screen and (min-width: 1000px) {
        .card-body {
            padding: 3.25rem;
        }
    }
}

/*-----------------------------------------
* Category
-----------------------------------------*/
.category {
    border-radius: 3px;
    box-shadow: 0px 3px 8.46px 0.54px rgba(111, 111, 111, 0.16);
    h3 {
        margin-bottom: .35rem;
        font-size: 18px;
        font-weight: bold;
    }
    h3, p {
        color: $dark;
    }
    p {
        font-size: 14.5px;
    }
}

/*-----------------------------------------
* Image Message - about page ceo message
-----------------------------------------*/
.image-message-padding-left {
    @media screen and (min-width: 1000px) {
        .wrap {
            padding-left: 4rem;
        }
    }
    @media screen and (min-width: 1800px) {
        .wrap {
            padding-left: 0;
        }
    }
}
.image-message {
    @media screen and (min-width: 1000px) {
        padding-top: 0 !important;
        p {
            font-size: 1.1rem;
        }
    }
    @media screen and (max-width: 960px) {
        .img-fluid, .img-fluid-force {
            margin-bottom: 50px;
        }
    }
}
.key-image-message {
    .wrap {
        padding: 20px 0;
        @media screen and (max-width: 1060px) {
            padding: 40px 0;
        }
    }
    .key {
        position: relative;
        padding-left: 42px;
        img {
            position: absolute;
            top: -8px;
            left: 0;
        }
        svg {
            position: absolute;
            top: -10px;
            left: 0;
            stroke: $blue;
        }
    }
    @media screen and (min-width: 1000px) {
        padding-top: 0 !important;
        p {
            font-size: 1.1rem;
        }
    }
    @media screen and (max-width: 960px) {
        .img-fluid, .img-fluid-force {
            margin-bottom: 1.5rem;
        }
    }
}
.become-image {
    @media screen and (max-width: 960px) {
        .img-fluid, .img-fluid-force {
            margin: 1.5rem 0;
        }
    }
}

/*-----------------------------------------
* Category Badges
-----------------------------------------*/
.category-badges {
    .badge {
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 0.8rem;
        font-weight: 500;
        background-color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(172, 172, 172);
        border-radius: 3px;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: $white;
        }
    }
    .active .badge {
        background-color: $green;
        border-color: $green;
        color: $white;
    }
    @media screen and (min-width: 1000px) {
        margin-left: 24px;
    }
}

/*-----------------------------------------
* Responsive Ipanema Advert
-----------------------------------------*/
.-ad-ipanema {
    background: url('/assets/img/promotions/800x600-background-image.jpg') no-repeat center center;
    background-size: cover;
    padding-right: 32px !important;
    p {
        font-weight: 500;
        line-height: 1;
        color: $white;
    }
    @media screen and (max-width: 500px) {
        br {
            display: none;
        }
    }
    @media screen and (max-width: 960px) {
        padding-right: 52px !important;
    }
}
.search-page, // Search Page
.-ad-news { // News Index Page
    height: 92%;
    .-ad-ipanema {
        height: 100%;
    }
}
.-ad-news { // News Index Page
    .-ad-ipanema {
        background-position: right center;
    }
}

/*-----------------------------------------
* Property Advert
-----------------------------------------*/
.-advert{
    border-radius: 3px;
    overflow: hidden;
    height: 290px; // works with min-height: 290px in .property rule above
    a,
    .-advert-image {
        max-height: 100%;
    }
}

/*-----------------------------------------
* Property
-----------------------------------------*/
.property {
    position: relative;
    min-height: 290px;
    border-radius: 3px;
    overflow: hidden;
    &:before {
        position: absolute;
        content: ' ';
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        border-radius: 3px;
        z-index: 20;
    }
    &:after {
        position: absolute;
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('/assets/img/property-image-overlay-min-v1.1.png') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        border-radius: 3px;
        z-index: 20;
    }
    .ontop {
        position: relative;
        z-index: 30;
    }
    .property-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
    }
    .icon {
        position: absolute;
        padding: 1rem 1.5rem;
        top: 0;
        right: 0;
        cursor: pointer;
        svg {
            color: $white;
        }
    }
    p {
        color: $white_ish2;
        margin-bottom: 0;
        b {
            display: block;
            color: $white;
            font-size: 22px;
        }
    }
    .down5 {
        position: relative;
        top: 5px;
    }
    .bottom {
        position: absolute;
        padding: 1rem 1.5rem;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
    }
    .hover {
        position: absolute;
        padding: 1.5rem;
        width: 100%;
        bottom: -200px;
        left: 0;
        right: 0;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        p {
            font-weight: bold;
        }
    }
    &:hover {
        &:before {
            background-color: rgba(50, 50, 51, 0.5);
        }
        .hover {
            bottom: 0;
        }
        .bottom {
            bottom: 120px;
        }
    }
}
.property-bar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1.5rem 0;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: .35s ease-in-out;
    -webkit-transition: .35s ease-in-out;
    -moz-transition: .35s ease-in-out;
    -o-transition: .35s ease-in-out;
    &.fixed {
        overflow: auto;
        height: auto;
        opacity: 1;
        top: 82px;
        z-index: 950;
        @media screen and (max-width: 960px) {
            top: 63px;
        }
    }
    @media screen and (max-width: 960px) {
        h1 {
            font-size: 16px;
            b {
                font-size: 20px;
            }
        }
        .price {
            font-size: 24px;
        }
    }
}

/*-----------------------------------------
* Search Page
-----------------------------------------*/
.search-row {
    .form-control {
        padding: 15px 25px;
        border-radius: 0;
        &:focus {
            outline: none;
            border-color: $white_ish;
            box-shadow: none;
        }
    }
    .location {
        position: relative;
        .form-control {
            padding-left: 30px !important;
        }
        &:after {
            position: absolute;
            content: ' ';
            display: block;
            margin: auto 0;
            height: 19px;
            width: 18px;
            top: 0;
            left: 0;
            bottom: 0;
            background-image: url('/assets/img/search-gray-icon.png');
            background-repeat: no-repeat;
            background-position: center center;
            border-top-left-radius: none;
            border-top-right-radius: none;
        }
    }
    @media screen and (min-width: 1000px) {
        .form-control {
            border-right: 1px solid $white_ish;
        }
    }
    @media screen and (max-width: 960px) {
        .form-control {
            padding: 15px;
            border-bottom: 1px solid $white_ish;
        }
        .location {
            width: 100%;
        }
        .type, .price, .bed {
            width: 50%;
            .form-control {
                width: 100%;
            }
        }
        .btn {
            margin: 10px 0;
            width: 100%;
        }
    }
}
.development {
    border-radius: 3px;
}
.development-search-title-row {
    h1 {
        font-size: 29px;
        color:$dark;
        span {
            font-size: 19px;
        }
    }
}
.search-title-row {
    h1 {
        font-size: 21.234px;
        color: rgb(101, 100, 100);
        span {
            font-size: 15.234px;
        }
        @media screen and (max-width: 960px) {
            font-size: 18px;
        }
    }
    a {
        color: rgb(101, 100, 100);
    }
    .button-text {
        color: rgb(101, 100, 100);
        cursor: pointer;
        &:hover {
            color: $blue !important;
        }
    }
}
.search-wrap {
    border-bottom: 1px solid $white_ish;
    .mobile {
        display: none !important;
    }
    .btn-green {
        &:focus, &.focus {
            outline: 0;
            box-shadow: none;
        }
    }
    @media screen and (max-width: 1199px) {
        position: fixed;
        background-color: $white;
        width: 100%;
        z-index: 950;
        .controls {
            overflow: hidden;
            height: 0;
            opacity: 0;
            transition: .35s ease-in-out;
            -webkit-transition: .35s ease-in-out;
            -moz-transition: .35s ease-in-out;
            -o-transition: .35s ease-in-out;
        }
        .mobile {
            display: flex !important;
            padding: 10px 0;
            .btn {
                padding: 0;
                margin: 0;
                text-align: left;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &:hover {
                    color: $dark !important;
                }
            }
            a, p {
                padding: 0 12px;
                cursor: pointer;
                svg {
                    color: $blue;
                }
            }
        }
        &.is-active {
            .mobile {
                border-bottom: 1px solid $white_ish;
            }
            .controls {
                overflow: visible;
                height: auto;
                opacity: 1;
            }
        }
        .search-toggle {
            flex: 1;
            color: $dark;
            text-transform: none;
            font-weight: 500 !important;
            text-decoration: none !important;
            svg {
                color: $blue;
            }
        }
    }
}

/*-----------------------------------------
* Link row
-----------------------------------------*/
.link-row {
    a {
        font-size: 16px;
        color: rgb(101, 100, 100);
        &:hover {
            color: rgba(101, 100, 100, 0.8);
        }
        img {
            width: 17px;
        }
        @media screen and (max-width: 1400px) {
            font-size: 15px;
            img {
                width: 15px;
            }
        }
        @media screen and (min-width: 1550px) {
            font-size: 18px;
        }
    }
}

/*-----------------------------------------
* Benefit
-----------------------------------------*/
.benefit {
    padding: 2.25rem;
    border-radius: 3px;
    background-color: $light_gray;
    .icon-wrap {
        padding-right: 2.25rem;
        min-width: 128px;
        min-height: 128px;
    }
    h3 {
        font-size: 25px;
    }
    p {
        color: $dark;
    }
    @media screen and (max-width: 1060px) {
        min-height: 343px;
    }
    @media screen and (max-width: 960px) {
        text-align: center;
        .icon-wrap {
            padding-right: 0;
            margin: 0 auto;
        }
    }
}

/*-----------------------------------------
* Members
-----------------------------------------*/
.members-menu {
    border-bottom: 1px solid $gray;
    .link {
        padding: 20px;
        color: $light_dark;
        font-weight: 500;
        svg {
            stroke: $light_dark;
            margin-bottom: 5px;
        }
        &:hover, &.active {
            color: $blue;
            svg {
                stroke: $blue;
            }
        }
    }
}

/*-----------------------------------------
* Development Details
-----------------------------------------*/
.developments-highlight {
    .full-wrap {
        height: 650px;
        @media screen and (max-width: 960px) {
            height: 425px;
        }
        @media screen and (max-width: 760px) {
            height: 325px;
        }
    }
    .half-wrap {
        height: calc(50% - 0.5rem);
    }
    .buttons {
        position: absolute;
        top: 25px;
        right: 50px;
    }
    .col-lg-4 {
        @media screen and (max-width: 960px) {
            display: none;
        }
    }
}

.development-details-page {
    .sticky-bar {
        @media screen and (max-width: 960px) {
            h1 {
                font-size: 16px !important;
                b {
                    font-size: 20px !important;
                }
            }
            .price, .price span {
                font-size: 20px !important;
            }
        }
    }
}

ul.backlinks {
    padding-left: 1rem;
}
.backlinks {
    a {
        color: $blue;
    }
}
