.location .v-select {
    //padding: 20px 0;
    .vs__dropdown-toggle {
        border: none;
        color: #495057;
        padding: 15px 40px 15px 30px;
        border-radius: 0;
        background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
        @media screen and (min-width: 1000px) {
            border-right: 1px solid $white_ish;
        }
        @media screen and (max-width: 960px) {
            border-bottom: 1px solid $white_ish;
            padding: 15px 40px 15px 30px;
            background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
        }
    }
    .vs__search {
        padding: 0;
        margin: 0;
        color: #495057;
        font-size: 1rem;
        font-weight: 300;
    }
    .vs__selected {
        margin: 0;
        padding: 0;
    }
    .vs__selected-options {
        padding: 0;
    }
    .vs__actions {
        padding: 0;
        button {
            margin: 0;
        }
    }
}

.home-search {
    .v-select {
        height: 50px;
        position: relative;
        -ms-flex: 1 1 auto;
        -webkit-box-flex: 1;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
        background: $white;
    }
    .vs__search, .vs__search:focus {
        margin: 0;
        padding: 10px 15px;
        font-weight: 300;
        color: #495057;
    }
    .vs__dropdown-toggle {
        padding: 0;
        border: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .vs__selected {
        margin: 0;
        padding: 10px 15px;
    }
    .vs__selected-options {
        padding: 0;
    }
    .vs__actions {
        padding: 0;
        button {
            margin: 0;
        }
    }
}

.vselect-border {
    //padding: 20px 0;
    border: 1px solid #dee2e6 !important;
    border-radius: 3px;
    .vs__dropdown-toggle {
        border: none !important;
        color: #495057;
        padding: 10px 40px 10px 15px !important;
        border-radius: 0;
        background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
        @media screen and (min-width: 1000px) {
            border-right: 1px solid $white_ish;
        }
        @media screen and (max-width: 960px) {
            border-bottom: 1px solid $white_ish;
            background: $white url('/assets/img/arrow.svg') no-repeat right 15px center/10px 6px;
        }
    }
    .vs__search {
        padding: 0;
        margin: 0;
        color: #495057;
        font-size: 1rem;
        font-weight: 300;
    }
    .vs__selected {
        margin: 0;
        padding: 0;
    }
    .vs__selected-options {
        padding: 0;
    }
    .vs__actions {
        padding: 0;
        button {
            margin: 0;
        }
    }
}

.vselect-fix {
    height: 50px;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    background: white;
}

.select-bg--gray {
    .vs__dropdown-toggle {
        background: $white;
        border: none;
        border-radius: 0;
    }
    .vs__search {
        padding: 15px 40px 15px 25px;
    }
}

.custom-select--gray {
    background-color: $light_gray;
}

.custom-select--white {
    background-color: $white;
}
