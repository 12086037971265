.slick-prev {
    left: 15px;
    z-index: 900;
    &:before {
        display: none;
    }
}
.slick-next {
    right: 15px;
    z-index: 900;
    &:before {
        display: none;
    }
}
.categories, .key-locations {
    &.slick-initialized {
        &:after {
            margin: 20px 0;
            position: absolute;
            display: block;
            content: ' ';
            height: calc(100% - 40px);
            width: 155px;
            top: 0;
            left: -10px;
            background: url('/assets/img/left-blur-min.png') no-repeat left center;
        }
    }
    .slick-list {
        box-sizing: initial;
        padding: 20px 0px !important;
        margin: 0 -10px !important;
    }
    .slick-arrow {
        position: absolute;
        display: block;
        top: 50%;
        -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        transform: translate(0,-50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: 0;
        background: 0 0;
        padding: 0;
        height: 34px;
        width: 36px;
        border-radius: 100%;
        background: $white;
        box-shadow: 0px 3px 14.24px 1.76px rgba(111, 111, 111, 0.25);
        z-index: 900;
        img {
            height: 16px;
            width: 10px;
        }
        @media screen and (min-width: 650px) {
            padding: 5px;
            height: 40px;
            width: 42px;
            img {
                height: 20px;
                width: 12px;
            }
        }
    }
    .slick-left-arrow {
        left: -14px;
        @media screen and (min-width: 650px) {
            left: -20px;
        }
    }
    .slick-right-arrow {
        right: -14px;
        @media screen and (min-width: 650px) {
            right: -20px;
        }
    }
    .slick-slide {
        margin: 0 10px;
    }
    @media screen and (min-width: 1200px) {
        .slide {
            padding-left: 100px;
        }
    }
}

.details-slider {
    display: none;
    .slick-list {
        .slick-track {
            .slick-slide {
                div {
                    height: 300px;
                    @media screen and (min-width: 700px) {
                        height: 500px;
                    }
                    @media screen and (min-width: 992px) {
                        height: 500px;
                    }
                    @media screen and (min-width: 1400px) {
                        height: 689px;
                    }
                }
            }
        }
    }
    .slick-disabled {
        display: none !important;
    }
}

.developments-slider {
    display: none;
}

.featured-developments-slider {
    min-height: 295px;
    .slide {
        display: none !important;
    }
    .slick-slide .slide {
        display: inline-block !important;
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        display: block;
        cursor: pointer;
        -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        transform: translate(0,-50%);
        z-index: 900;
    }
    .slick-left-arrow {
        left: -14px;
        .white-arrow {
            display: none;
        }
        @media screen and (min-width: 1650px) {
            left: -80px;
        }
        @media screen and (max-width: 860px) {
            left: 5px;
            .gray-arrow {
                display: none;
            }
            .white-arrow {
                display: block;
            }
        }
    }
    .slick-right-arrow {
        right: -14px;
        .white-arrow {
            display: none;
        }
        @media screen and (min-width: 1650px) {
            right: -80px;
        }
        @media screen and (max-width: 860px) {
            right: 5px;
            .gray-arrow {
                display: none;
            }
            .white-arrow {
                display: block;
            }
        }
    }
    @media screen and (max-width: 860px) {
        .slick-arrow {
            top: 225px;
            -webkit-transform: translate(0,-16%);
            -ms-transform: translate(0,-16%);
            transform: translate(0,-16%);
            z-index: 900;
        }
        .img-wrap {
            height: 450px;
            width: 100%;
        }
    }
    @media screen and (max-width: 760px) {
        .slick-arrow {
            top: 105px;
            -webkit-transform: translate(0,-16%);
            -ms-transform: translate(0,-16%);
            transform: translate(0,-16%);
            z-index: 900;
        }
        .img-wrap {
            height: 240px;
            width: 100%;
        }
    }
}

.key-locations {
    &.slick-initialized {
        &:after {
            display: none;
        }
    }
    .slick-arrow {
        top: 56% !important;
        -webkit-transform: translate(0,-56%) !important;
        -ms-transform: translate(0,-56%) !important;
        transform: translate(0,-56%) !important;
    }
    .slick-track {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.slick-disabled {
    display: none !important;
}

.slick-initialized {
    display: block !important;
}

.home-page .categories {
    height: 149px;
    .category {
        display: none !important;
    }
    .slick-slide .category {
        display: flex !important;
    }
}

.slick-center {
    .slick-slide {
        transition: all 300ms ease-in-out;
    }
    .slick-slide {
        > div {
            padding: 25px;
            transition: all 300ms ease-in-out;
        }
        &.slick-current {
            > div {
                transform: scale(1.2);

                @media screen and (max-width: 479px) {
                    transform: scale(1.3);
                }
            }
        }
    }
}

.slick-center-nav {
    padding-left: 30px;
    padding-right: 30px;

    &-wrapper {
        transform: translateY(-60px);

        @media screen and (max-width: 479px) {
            transform: translateY(-40px);
        }
    }

    .slick-dots {
        position: initial;
        bottom: initial;
        width: initial;

        li {
            button:before {
                color: $white;
                font-size: 14px;
                line-height: 16px;
                opacity: 0.65;
            }
            &.slick-active button:before {
                opacity: 1;
            }
        }
    }
    .slick-arrow {
        background-size: cover;
        width: 28px;
        height: 25px;

        &.slick-prev {
            background-image: url(/assets/img/vendor/images/prev.png);
            left: 0;
        }

        &.slick-next {
            background-image: url(/assets/img/vendor/images/next.png);
            right: 0;
        }
    }
}