@import url("https://use.typekit.net/bdt2uzk.css");

body {
    font-family: nimbus-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}
.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    font-family: nimbus-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.btn {
    font-family: nimbus-sans, sans-serif;
    font-weight: 700 !important;
    font-style: normal;
}
.box {
    .details .font-bold {
        font-family: nimbus-sans, sans-serif;
        font-weight: 700;
        font-style: normal;
    }
}

.f-light, .f-300 {
    font-weight: 300;
}

.f-regular {
    font-weight: normal;
}

.f-medium, .f-500 {
    font-weight: 500;
}

.f-semibold, .f-600 {
    font-weight: 600;
}

.f-bold, .f-700 {
    font-weight: 700;
}

.f-extrabold, .f-800 {
    font-weight: 800;
}

.f-black, .f-900 {
    font-weight: 900;
}

.f-normal {
    font-style: normal;
}

.f-italic {
    font-style: italic;
}

.u-font-85-important{
    font-size: 85px !important;
}

.u-font-90-important{
    font-size: 90px !important;
}

@media screen and (max-width: 600px) {
    .u-font-sm-85-important{
        font-size: 70px !important;
    }
}

@for $j from 11 through 82
{
    .f-#{$j}
    {
        @include font-size($j);
    }
}

// Shrink fonts 87.5% at 960px width or lower
@media (max-width: 960px) {
    @for $j from 11 through 82
    {
        .f-scaledown.f-#{$j}
        {
            @include font-size($j * .875);
        }
    }
}
// Shrink fonts 75% at 600px width or lower
@media (max-width: 600px) {
    @for $j from 11 through 82
    {
        .f-scaledown.f-#{$j}
        {
            @include font-size($j * .75);
        }
    }
}
