// Developments...
.development-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 60%;
    }
}

.development-image--landscape {
    &:before {
        padding-top: 30%;
    }
}

.development-image--square {
    &:before {
        padding-top: 70%;
    }
}

.development-lp-overview-img{
    height: 300px;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 1199px) {
        height: 240px;
    }
    @media screen and (max-width: 767px) {
        height: auto;
    }
}

.development-keyfeatures-bg{
    background-position: center;
    background-size: cover;
}
.development-keyfeatures-bg--full{
    width: 100%;
    height: 100%;
}

.development-holder-overlay {
    background: rgba($blue, 0.9);
    width: 100%;
    height: 100%;
}

.development-page-footer {
    background-color: $blue;
    background-position: center;
    background-size: cover;
    height: 50vh;
    position: relative;
    @include media-breakpoint-down(sm)
    {
        h2 {
            font-size: 34px;
        }
        h3 {
            font-size: 22px;
        }
    }
}

.development-landing-list {
    margin: 0;
    padding: 0;
    li {
        float: left;
        position: relative;
        list-style: none;
        display: block;
        width: 100%;
        color: $blue;
        &:before {
            content: "\f00c";
            font-family: 'FontAwesome';
            font-size: 18px;
            position: absolute;
            top: 0;
        }
        span {
            display: inline-block;
            padding-left: 2rem;
            color: $white;
        }
    }
}
