[v-cloak] .v-cloak--block {
    display: block;
}
[v-cloak] .v-cloak--inline {
    display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
    display: inline-block;
}
[v-cloak] .v-cloak--hidden {
    display: none;
}
[v-cloak] .v-cloak--invisible {
    visibility: hidden;
}

.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
    display: none;
}

.categories-placeholder {
    padding: 20px 0px;
    @media screen and (max-width: 1199px) {
        margin-top: 50px;
    }
}

.search-row {
    .placeholder {
        padding: 15px 25px;
        height: 54px;
    }

    .type .placeholder {
        min-width: 223px;
    }

    .price .placeholder {
        min-width: 155px;
    }

    .bed .placeholder {
        min-width: 101px;
    }

    @media screen and (min-width: 1000px) {
        .placeholder {
            border-right: 1px solid $white_ish;
        }
    }
    @media screen and (max-width: 960px) {
        .placeholder {
            padding: 15px;
            border-bottom: 1px solid $white_ish;
        }
    }
    @media screen and (max-width: 1199px) {
        .placeholder {
            padding: 10px 0;
        }
    }
}

.placeholder {
    font-size: 0;
    line-height: 0;

    .p-text {
        display: inline-block;
        background-color: #444;
        height: 12px;
        border-radius: 100px;
        margin: 5px 0;
        opacity: .1;
        animation: fading 1.5s infinite;

        &.p-header {
            height: 25px;
        }

        &.p-link {
            background-color: var(--blue);
            opacity: .4;
        }

        &.p-line {
            width: 100%;
        }
    }

    .p-box {
        display: inline-block;
        background-color: #444;
        height: 80px;
        width: 100%;
        border-radius: 3px;
        opacity: .1;
        animation: fading 1.5s infinite;

        &.p-property {
            height: 290px;
        }

        &.p-button {
            height: 100%;
        }
    }

    .p-search-box {
        display: inline-block;
        background-color: #444;
        height: 50px;
        width: 100%;
        border-radius: 3px;
        opacity: .1;
        animation: fading 1.5s infinite;

        &.p-property {
            height: 290px;
        }

        &.p-button {
            height: 100%;
        }
    }
}

@keyframes fading {
    0% {
        opacity: .1;
    }

    50% {
        opacity: .2;
    }

    100% {
        opacity: .1;
    }
}

.placeholder-search-slider {
    padding: 20px 0px;
    @media screen and (max-width: 1199px) {
        margin-top: 52px;
    }
}