.content {
    padding: 80px 0;
    h1 {
        margin-bottom: 20px;
    }
    h2 {
        margin-bottom: 20px;
        @media screen and (min-width: 1000px) {
            &.big {
                font-size: 55px;
            }
        }
    }
    @media screen and (max-width: 960px) {
        padding: 50px 0;
        h2 {
            font-size: 1.6rem;
        }
    }
}

/*-----------------------------------------
* CTA - Call To Actions
-----------------------------------------*/
.cta {
    padding: 100px;
    border-radius: 3px;
    h2 {
        margin-bottom: 30px;
        font-size: 2.5rem;
    }
    @media screen and (max-width: 960px) {
        padding: 50px 25px;
        background-position: left center;
        text-align: left;
        h2 {
            font-size: 1.85rem;
        }
    }
}
.cta-home {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media screen and (max-width: 960px) {
        background: url('/assets/img/cta-mobile-min.jpg') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        h2 {
            width: 50%;
            line-height: 0.9;
            span {
                display: block;
                margin-top: 5px;
                font-size: 1.75rem;
            }
        }
    }
    @media screen and (max-width: 760px) {
        h2 {
            span {
                margin-top: 15px;
            }
        }
    }
}
.cta-about {
    background: url('/assets/img/about-page/cta-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.cta-agent {
    background: url('/assets/img/agents-directory-cta-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 50px;
    border-radius: 0;
    h2 {
        font-size: calc(2.5rem - 5px);
    }
    @media screen and (max-width: 960px) {
        background-position: left center;
    }
}

/*-----------------------------------------
* Advertising Benefits
-----------------------------------------*/
.advertising-benefits {
    background: url('/assets/img/special-background-min.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .special-control {
        margin-bottom: 50px;
        cursor: pointer;
        span {
            margin: 0 0 0 20px;
            padding: 0 0 0 20px;
            border-left: 3px solid $white;
            font-weight: 100;
        }
    }
    .flip {
        font-weight: 100 !important;
        span {
            font-weight: 500;
        }
    }
    .special-image {
        border-radius: 3px;
    }
    .special-slider {
        width: 100%;
    }
    @media screen and (min-width: 1550px) {
        .special-image {
            min-width: 565px;
        }
    }
    @media screen and (min-width: 1200px) {
        .special-slider {
            padding-left: 30px;
        }
    }
    @media screen and (max-width: 1199px) {
        text-align: left;
        background: url('/assets/img/special-background-mobile-min.png') no-repeat left center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        .special-image {
            margin-bottom: 30px;
        }
    }
}

/*-----------------------------------------
* Newsletter
-----------------------------------------*/
.newsletter {
    padding: 60px 0;
    .newsletter-email-input {
        @media screen and (min-width: 1200px) {
            width: 350px !important;
        }
        @media screen and (max-width: 1199px) {
            margin-right: 0 !important;
            width: calc(60% - 10px);
        }
        @media screen and (max-width: 560px) {
            width: calc(60% - 10px);
        }
    }
    .newsletter-fullname-input {
        @media screen and (max-width: 1199px) {
            width: 40%;
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 1199px) {
        padding-bottom: 45px;
        .container {
            display: block !important;
        }
        h3 {
            margin-right: 0 !important;
            margin-bottom: 20px;
        }
        .form-control, button {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 960px) {
        .btn-green {
            min-width: 40%;
        }
    }
}

/*-----------------------------------------
* Gray with Image
-----------------------------------------*/
.gray-with-image {
    padding: 50px 25px;
    border-radius: 3px;
    background-color: rgb(242, 242, 242);
    @media screen and (min-width: 1600px) {
        .wrap {
            padding: 0 3rem;
        }
    }
    @media screen and (min-width: 1200px) {
        .wrap {
            padding: 0;
        }
    }
    @media screen and (min-width: 1000px) {
        .wrap {
            padding: 0;
        }
    }
}

/*-----------------------------------------
* About - homepage about
-----------------------------------------*/
.about {
    @media screen and (max-width: 960px) {
        border-radius: none;
        text-align: left;
    }
}

/*-----------------------------------------
* Pagination
-----------------------------------------*/
.pagination {
    .page-link {
        cursor: pointer;
        margin-bottom: 0;
        border: none !important;
        border-radius: 0 !important;
        font-size: 16px;
        color: rgb(101, 100, 100);
        &:hover {
            background-color: $light_gray;
        }
        svg {
            color: rgb(101, 100, 100);
        }
    }
    .page-item.active .page-link {
        padding-bottom: calc(0.25rem - 2px);
        color: rgb(101, 100, 100);
        border-bottom: 2px solid $blue !important;
        background-color: transparent;
    }
}

/*-----------------------------------------
* Property Details
-----------------------------------------*/
.details-align {
    @media screen and (min-width: 1700px) {
        .col-lg-8 {
            flex: 0 0 63%;
            max-width: 63%;
        }
        .col-lg-4 {
            flex: 0 0 37%;
            max-width: 37%;
        }
    }
}

.details {
    .wrap {
        padding: 2rem;
        width: 100%;
    }
    h1 {
        font-size: 20px;
        color: rgba(101, 100, 100, 0.8);
        b {
            font-size: 29px;
            font-weight: 500;
            color: $dark;
        }
    }
    .bed-bath {
        font-size: 14.478px;
        font-weight: 500;
        color: rgb(101, 100, 100);
        @media screen and (max-width: 1400px) {
            padding: 5px 0 !important;
        }
    }
    .price {
        font-size: 35px;
        font-weight: 600;
        color: $dark;
        @media screen and (max-width: 1400px) {
            font-size: 33px;
            padding-bottom: 0 !important;
            margin-bottom: 5px !important;
        }
    }
    .horizontal-line {
        display: block;
        width: 100%;
        height: 2px;
        margin: 50px 0;
        background-color: rgba(63, 63, 63, 0.102);
        @media screen and (max-width: 1400px) {
            margin: 25px 0;
        }
        @media screen and (max-width: 960px) {
            margin: 2rem 0 calc(2rem - 10px) 0;
        }
    }
    .btn-blue {
        @media screen and (max-width: 760px) {
            margin-bottom: 0 !important;
        }
    }
    .share {
        font-size: 25px;
        font-weight: 500;
        color: $dark;
    }
    @media screen and (max-width: 1500px) {
        .icon-wrap {
            width: 20px !important;
        }
        .share-option {
            font-size: 15px;
        }
    }
    @media screen and (max-width: 960px) {
        .wrap {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.details-slider-wrap {
    position: relative;
    .buttons {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 15px;
        z-index: 1000;
        @media screen and (min-width: 1000px) {
            left: 58px;
        }
        @media screen and (min-width: 1500px) {
            left: 85px;
        }
    }
    .video-overlay, .map-overlay {
        position: absolute;
        opacity: 0;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 950;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        &.visible {
            opacity: 1;
            display: block;
            transition: .15s ease-in-out;
            -webkit-transition: .15s ease-in-out;
            -moz-transition: .15s ease-in-out;
            -o-transition: .15s ease-in-out;
        }
        .property-map {
            width: 100%;
            height: 100%;
        }
    }
    .btn {
        padding: 10px 15px;
        font-size: 15px;
    }
    @media screen and (max-width: 960px) {
        .btn {
            padding: 10px;
            font-size: 10px;
        }
    }
}

/*-----------------------------------------
* Coverage
-----------------------------------------*/
.coverage {
    background-repeat: no-repeat;
    background-position: right;
    background-color: #3F3F3F;
    overflow-x: hidden;
    @media screen and (max-width: 960px) {
        background-position: left;
    }
    h2 {
        font-size: 40px;
    }
    p {
        font-size: 18px;
    }
    .wrap {
        padding: 2.25rem;
        background-color: rgba(101, 100, 100, 0.9);
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        @media screen and (min-width: 1000px) {
            padding: 3.25rem;
        }
        &:after {
            position: absolute;
            content: ' ';
            display: block;
            top: 0;
            left: -100%;
            height: 100%;
            width: 100%;
            background-color: rgba(101, 100, 100, 0.9);
        }
    }
    @media screen and (min-width: 1000px) {
        .row {
            min-height: 570px;
        }
    }
}

/*-----------------------------------------
* Featured
-----------------------------------------*/
.featured {
    .nav-link {
        font-size: 14px;
    }
    @media screen and (max-width: 1000px) {
        h2 {
            text-align: center;
        }
    }
}

/* Valuations Page
-------------------------------------------- */

.market-price {
    transition: 0.5s;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid $white_ish;
    border-bottom: 1px solid $white_ish;
    .market-price__inner {
        h3 {
            margin-bottom: 2rem;
        }
    }
    &.-scroll {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        position: fixed;
        width: 100%;
        z-index: 999;
        background: $white;
        top: 0;
        .market-price__inner {
            h3 {
                @include font-size(22);
                margin-bottom: 0.5rem;
            }
            p {
                @include font-size(14);
            }
        }
    }
}

.valuation-map {
    height: 300px;
}
