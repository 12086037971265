.btn {
    padding: 10px 30px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $white;
    &:hover {
        color: $white !important;
    }
}
.btn:focus {
    box-shadow: none !important;
}
.btn-green {
    background-color: $green;
    &:hover {
        background-color: $green_hover;
    }
}
.btn-gray {
    background-color: #656364;
    &:hover {
        background-color: #656364;
    }
    &.-chosen {
        background-color: $blue !important;
    }
}
.btn-blue {
    background-color: $blue;
    &:hover {
        background-color: $blue_hover;
    }
}
.btn-dark {
    background-color: rgba(51, 51, 52, 0.8);
    &:hover {
        background-color: rgb(51, 51, 52);
    }
}
.btn-pink {
    background-color: $pink;
    &:hover {
        background-color: $pink_hover;
    }
}
.btn-outline {
    background-color: transparent;
    text-transform: none;
    border: 1px solid #dee2e6;
    color: $dark !important;
    &:hover {
        color: $light_dark !important;
    }
}
.social-btn {
    padding: 0;
    border-radius: 3px;
    .icon {
        padding: 10px 15px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .text {
        padding: 10px 15px;
        color: #fff;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    @media screen and (max-width: 960px) {
        .icon {
            padding: 10px 5px;
        }
        .text {
            padding: 10px 5px;
        }
    }
    &.-facebook {
        background: #4769BA;
        .icon {
            background: #3F5EA7;
        }
        .text {
            background: #4769BA;
        }
        &:hover {
            .icon {
                background: lighten(#3F5EA7, 3%);
            }
            .text {
                background: lighten(#4769BA, 3%);
            }
        }
    }
    &.-whatsapp {
        background: rgb(49, 174, 123);
        .icon {
            background: rgb(55, 194, 137);
        }
        .text {
            background: rgb(49, 174, 123);
        }
        &:hover {
            .icon {
                background: lighten(rgb(55, 194, 137), 3%);
            }
            .text {
                background: lighten(rgb(49, 174, 123), 3%);
            }
        }
    }
    &.-email {
        background: rgb(12, 135, 191);
        .icon {
            background: rgb(14, 151, 213);
        }
        .text {
            background: rgb(12, 135, 191);
        }
        &:hover {
            .icon {
                background: lighten(rgb(14, 151, 213), 3%);
            }
            .text {
                background: lighten(rgb(12, 135, 191), 3%);
            }
        }
    }
}

.share-gray-button {
    height: 46px;
    border-radius: 3px;
    @media screen and (max-width: 760px) {
        background-color: #f1f1f1;
    }
}
