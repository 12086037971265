:focus {
    outline: none;
}
.iti {
    width: 100%;
}
#orderbymobile {
    font-size: 14px;
}
.only-mobile {
    display: none !important;
}
.development-details-page {
    .sticky {
        position: relative;
        top: 0px;
        transition: .35s ease-in-out;
        -webkit-transition: .35s ease-in-out;
        -moz-transition: .35s ease-in-out;
        -o-transition: .35s ease-in-out;
    }
    .fixed100 {
        position: fixed;
        top: -100px;
        transition: .35s ease-in-out;
        -webkit-transition: .35s ease-in-out;
        -moz-transition: .35s ease-in-out;
        -o-transition: .35s ease-in-out;
    }
    .fixed {
        position: fixed;
        height: auto;
        width: 100%;
        top: 82px;
        z-index: 950;
        transition: .35s ease-in-out;
        -webkit-transition: .35s ease-in-out;
        -moz-transition: .35s ease-in-out;
        -o-transition: .35s ease-in-out;
        @media screen and (max-width: 960px) {
            top: 63px;
        }
    }
}
.mobile-only {
    display: none;
    @media screen and (max-width: 1199px) {
        display: block;
    }
}
.mobile-r-only {
    display: none;
    @media screen and (max-width: 960px) {
        display: block;
    }
}
.article-content {
    img {
        height: auto;
        width: 100%;
    }
}
.search-toggle {
    letter-spacing: normal;
}
.orderby {
    width: auto !important;
    padding: 0 20px 0 5px !important;
    background: white url(/assets/img/arrow.svg) no-repeat right 5px center/10px 6px;
    color: #656464;
    box-shadow: none !important;
}
.hero-subtitle {
    position: relative;
    top: -9px;
    @media screen and (max-width: 960px) {
        margin: 0 auto;
        width: 80%;
    }
}
.img-fluid-force {
    height: auto;
    width: 100%;
}
.img-fluid-force-h {
    height: 100%;
    width: auto;
}
.img-fluid-force-hw {
    height: 100%;
    width: 100%;
}
.special-align {
    @media screen and (min-width: 1700px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
}
.weight-500 {
    font-weight: 500 !important;
}
.up-2 {
    position: relative;
    top: -2px;
}
img {
    border-radius: 3px;
}
.hide-item {
    display: none !important;
}
.shadow-none {
    box-shadow: none !important;
}
.custom-row {
    display: flex;
    flex-flow: row wrap;
}
.bs {
    box-shadow: 0px 3px 15.04px 0.96px rgba(111, 111, 111, 0.14);
}
button:focus {
    outline: none;
}
a:hover {
    text-decoration: none;
}
.border-radius {
    border-radius: .25rem !important;
}
.text-normal {
    text-transform: none !important;
}
.horizontal-line {
    display: block;
    height: 100%;
    width: 3px;
    background-color: rgba(255, 255, 255, 0.5);
}
.horizontal-line-alt {
    display: block;
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background-color: rgb(101, 100, 100);
    opacity: 0.2;
}
.first-content {
    padding-top: 140px !important;
    @media screen and (max-width: 960px) {
        padding-top: 100px !important;
    }
}
.first-flush-content {
    padding-top: 82px !important;
    @media screen and (max-width: 960px) {
        padding-top: 63px !important;
    }
}
.share-option {
    color: #212529;
}
.no-wrap {
    white-space: nowrap;
}
.viewing-spacing {
    margin-top: 1rem;
    padding-top: 1rem;
    @media screen and (min-width: 1000px) {
        margin-top: 2.5rem;
        padding-top: 2.5rem;
    }
}
.w-90 {
    width: 90%;
}
.w-80 {
    @media screen and (max-width: 960px) {
        width: 80%;
    }
}
.w-60 {
    @media screen and (min-width: 1000px) {
        width: 60%;
    }
}
.w-55 {
    @media screen and (min-width: 1000px) {
        width: 55%;
    }
}
.become-page {
    .heart-icon {
        width: 92px;
        height: 81px;
    }
    .bulb-icon {
        width: 100px;
        height: 100px;
    }
    .star-icon {
        width: 100px;
        height: 100px;
    }
    .home-icon {
        width: 89px;
        height: 83px;
    }
    /*
    @media screen and (max-width: 960px) {
        .heart-icon {
            width: 92px;
            height: 81px;
        }
        .bulb-icon {
            width: 100px;
            height: 100px;
        }
        .star-icon {
            width: 100px;
            height: 100px;
        }
        .home-icon {
            width: 89px;
            height: 83px;
        }
    }
    */
}
.date-icon-overlay {
    position: relative;
    svg {
        position: absolute;
        right: 8px;
        top: calc(50% - 14px);
    }
}
.tick-bullet {
    position: relative;
    padding-left: 42px;
    svg {
        position: absolute;
        top: -6px;
        left: 0;
        stroke: #0e97d5;
    }
}
.share-title {
    font-size: 25px;
    font-weight: 500;
}
.smaller-gutters {
    margin-right: 0;
    margin-left: 0;
}
.smaller-gutters > .col, .smaller-gutters > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.row{
    &.u-row-spacing-16{
        margin-left: -8px;
        margin-right: -8px;
        >div{
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    &.u-row-spacing-24{
        margin-left: -12px;
        margin-right: -12px;
        >div{
            padding-left: 12px;
            padding-right: 12px;
        }
    }
    &.u-row-spacing-26{
        margin-left: -13px;
        margin-right: -13px;
        >div{
            padding-left: 13px;
            padding-right: 13px;
        }
    }
}

.details-placeholder {
    min-height: 98px;
    @media (max-width: 767px) {
        min-height: 92px;
    }
}
.mt-1-tablet-only {
    @media screen and (min-width: 992px) and (max-width: 1268px) {
        margin-top: 0.75rem;
    }
}
.u-overflow-hidden-x{
    overflow-x: hidden;
}

.u-maxwidth-1130{
    max-width: 1130px;
}

.u-margin-center{
    margin-left: auto;
    margin-right: auto;
}

.u-top--15px{
    top: -15px;
}

.u-opacity-01{
    opacity: 0.1;
}

.placeholder-4x3 {
    padding-top: 75%;
}

.bg-cover {
    background-size: cover;
    background-position: center center;
}

@media screen and (max-width: 1400px) {
    .d-none-1400 {
        display: none;
    }
    .mr-none-1400 {
        margin-right: 0 !important;
    }
}
.development-details-page {
    form .mt-3 {
        font-size: 14px;
    }
}
.home-search {
    .searchable-dropdown, .form-control {
        flex-grow: 1 !important;
    }
    .form-control {
        width: 100%;
    }
    @media screen and (max-width: 960px) {
        .input-group {
            display: block;
            width: 100%;
        }
        .searchable-dropdown {
            flex: none;
            display: inline-block;
            width: 85% !important;
        }
        .input-group-append {
            display: inline-block;
            width: 12% !important;
        }
    }
}

.bg-cover {
    background-size: cover;
}

.filler {
    &-16x9:before {
        content: '';
        display: inline-block;
        padding-top: 56.25%;
    }

    &-4x3:before {
        content: '';
        display: inline-block;
        padding-top: 75%;
    }
}

@media screen and (max-width: 1140px) {
    .u-hide-1140{
        display: none;
    }
}


@media screen and (max-width: 991px) {
    .u-mt-sm-30{
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px) {
    .u-show-767{
        display: block;
    }
}

@media screen and (min-width: 1000px) {
    .area-insights-image {
        min-height: 280px;
        max-height: 280px;
        height: 280px;
    }
    .mb-only-1 {
        margin-bottom: .25rem !important;
    }
    .benefits-border-right {
        border-right: 3px solid $white_ish;
    }
    .agent-location-search {
        width: 350px !important;
    }
    .br-md-none {
        border-right: none !important;
    }
    .news-image {
        height: 180px;
    }
    .news-title {
        //height: 54px;
        height: 44px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .news-text {
        //height: 82px;
        //height: 62px;
        //text-overflow: ellipsis;
        //overflow: hidden;
    }
}
@media screen and (min-width: 1100px) {
    .news-image {
        height: 230px;
    }
}
@media screen and (max-width: 1199px) {
    .form-mobile-center {
        display: block !important;
        text-align: center;
    }
    .text-mobile-center {
        text-align: center !important;
    }
    .pa-mobile {
        padding: 0 25px;
    }
    .pl-mobile-0 {
        padding-left: 0 !important;
    }
    .px-mobile-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .mt-mob-4 {
        margin-top: 1.5rem;
    }
    .mb-mob-4 {
        margin-bottom: 1.5rem;
    }
    .container-mobile-flush {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .block-mobile {
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }
    .no-mobile {
        display: none !important;
    }
    .only-mobile {
        display: inline-block !important;
    }
}

@media screen and (max-width: 960px) {
    .fs-mob-14rm {
        font-size: 1.4rem !important;
    }
    .hw-mob-34 {
        height: 34px !important;
        width: 34px !important;
    }
    .pt-mob-2 {
        padding-top: 2rem !important;
    }
    .mt-mob-175 {
        margin-top: 1.75rem !important;
    }
    .mt-mob-2 {
        margin-top: 2rem !important;
    }
    .mt-mob-0 {
        margin-top: 0 !important;
    }
    .mb-mob-0 {
        margin-bottom: 0 !important;
    }
    .w-m-50 {
        width: 50%;
    }
    .d-mob-flex {
        display: flex !important;
    }
    .block-r-mobile {
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }
    .flex-r-mobile {
        display: flex !important;
    }
    .no-r-mobile {
        display: none !important;
    }
    .about-content {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 760px) {
    .text-mobiler-center {
        text-align: center;
    }
    .no-r2-mobile {
        display: none !important;
    }
    .block-rr-mobile {
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }
    .no-rr-mobile {
        display: none !important;
    }
}
@media (min-width: 516px) {
    .hide-above-516 {
        display: none !important;
    }
}
@media (max-width: 1198px) {
    .search-mob-spacing {
        height: 50px;
    }
}
@media (max-width: 767px) {
    .search-mob-spacing {
        height: 74px;
    }
}
