.footer {
    padding: 50px 0;
    background-color: $light_gray;
    .logo {
        margin-right: 30px;
    }
    .social {
        position: relative;
        top: 0;
        padding: 0 5px;
        transition: .15s ease-in-out;
        -webkit-transition: .15s ease-in-out;
        -moz-transition: .15s ease-in-out;
        -o-transition: .15s ease-in-out;
        &:hover {
            top: -3px;
        }
    }
    h4 {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    hr {
        margin-top: 30px;
        margin-bottom: 30px;
        border-top: 2px solid rgba(0,0,0,.1);
    }
    a {
        display: block;
        color: #212529;
        &:hover {
            color: #4d5053;
        }
    }
    .links a {
        margin-top: 15px;
        font-size: 14px;
    }
    .custom-row a {
        margin-right: 80px;
    }
    @media screen and (max-width: 960px) {
        .logo {
            margin-right: 15px;
        }
        .social-row {
            position: relative;
            top: -5px;
            margin-left: auto !important;
        }
        .links a {
            margin-top: 8px;
        }
        .custom-row {
            display: block;
            a {
                margin-right: 0;
            }
        }
    }
}
.bottom-footer {
    padding: 15px 0;
    p {
        margin: 0;
        color: #656565;
        font-size: 13px;
        span b {
            margin: 0 10px;
        }
    }
    a {
        color: #656565;
        &:hover {
            color: #747474;
        }
    }
    @media screen and (min-width: 1000px) {
        p span {
            margin-left: 50px;
        }
    }
    @media screen and (max-width: 960px) {
        p span {
            display: block;
        }
        .col-md {
            margin-bottom: 20px;
        }
    }
}