.navbar {
    padding: 20px 0;
    border-bottom: 1px solid $white_ish;
    z-index: 1020;
    svg {
        color: $blue;
    }
    .dropdown-item {
        &:active {
            background-color: transparent;
        }
    }
    @media screen and (min-width: 1200px) {
        .currency {
            border: 1px solid rgb(172, 172, 172);
            border-radius: 3px;
            font-weight: 500;
        }
    }
    @media screen and (max-width: 960px) {
        padding: 5px 0;
    }
    .quick-search-form-input {
        input {
            font-size: 0.95rem;
        }
    }
    .quick-search-loading {
        min-height: 30px;
        width: 100%;
    }
    .p-search-box {
        height: 30px !important;
        width: 100% !important;
    }
    @media (min-width: 1350px) {
        .quick-search-loading, .quick-search-input {
            min-width: 200px;
        }
    }
    @media (max-width: 767px) {
        .quick-search-input {
            font-size: 0.75rem;
        }
    }
}
.navbar-toggler {
    padding: 0 0 0 15px !important;
    @media screen and (max-width: 960px) {
        padding: 15px 0 15px 15px !important;
    }
}
.hamburger-box {
    width: 30px;
    height: 12px;
}
.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    width: 30px;
}
.navbar-light {
    .mr-auto, .login-register {
        font-weight: 500;
    }
    .navbar-nav {
        .nav-link {
            padding-right: 1rem;
            padding-left: 1rem;
            color: $dark;
            transition: .15s ease-in-out;
            -webkit-transition: .15s ease-in-out;
            -moz-transition: .15s ease-in-out;
            -o-transition: .15s ease-in-out;
            &:hover {
                color: rgba(0, 0, 0, 0.7) !important;
            }
        }
    }
    @media screen and (min-width: 1200px) {
        .navbar-nav .search .nav-link {
            border-radius: 3px;
            background-color: $blue;
            color: $white;
        }
    }
    @media screen and (max-width: 1199px) {
        .navbar-nav .nav-link {
            padding: 1rem 1rem;
            border-top: 1px solid $white_ish;
            font-weight: 400;
        }
        .mr-auto .nav-item:first-child .nav-link {
            margin-top: 5px;
        }
        .ml-auto .nav-item:last-child .nav-link {
            padding-bottom: 15px;
        }
        .quick-search-form-input {
            margin-left: 0 !important;
            padding: 1rem !important;
            margin-top: 0.5rem !important;
            border-top: 1px solid #d7d7d7;
            border-bottom: none;
            .quick-search-input {
                padding: 0 0 0 11px !important;
                font-size: 1rem !important;
                border-bottom: none !important;
            }
        }
    }
}
.about-page, .agent-directory-page, .contact-page, .become-page, .resort-complex-page {
    .hamburger-inner {
        background-color: $white_ish2 !important;
        &:before, &:after {
            background-color: $white_ish2 !important;
        }
    }
    .hamburger.is-active {
        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            background-color: $white_ish2 !important;
        }
    }
    .navbar-light {
        .navbar-nav {
            .nav-link {
                color: $white_ish2;
                &:hover {
                    color: rgba(254, 254, 254, 0.85) !important;
                }
            }
        }
        .quick-search-form-input, .quick-search-input {
            color: #ffffff;
            &::placeholder {
                color: #ffffff;
            }
        }
        @media screen and (max-width: 960px) {
            .navbar-nav .nav-link {
                border-top: 1px solid $dark;
            }
        }
    }
    .quick-link {
        color: $white_ish2;
    }
    .navbar {
        background-color: $light_dark !important;
        border-bottom: none;
    }
}
.large-header {
    position: relative;
    margin-top: 81px;
    padding: 80px 0;
    background-color: $light_dark;
    h1 {
        font-size: 55px;
    }
    p {
        font-size: 22px;
        color: rgba(255, 255, 255, 0.6);
    }
    @media screen and (max-width: 960px) {
        margin-top: 56px;
        h1 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1rem;
        }
    }
}
.large-header-for-image {
    padding: 80px 0 420px 0;
    @media screen and (max-width: 960px) {
        padding: 80px 0 120px 0;
    }
}
.contact-page {
    .large-header-for-image {
        padding: 80px 0 300px 0 !important;
    }
    .hero-large-pull-up {
        margin: -250px auto 0 auto !important;
    }
}
.become-page {
    @media screen and (max-width: 960px) {
        .large-header-for-image {
            padding: 80px 0 300px 0 !important;
        }
        .hero-large-pull-up {
            margin: -250px auto 0 auto !important;
        }
    }
}
.agent-profile-page {
    @media screen and (max-width: 960px) {
        h1 {
            font-size: 2rem;
        }
    }
}
.search-page {
    .categories {
        &> .slick-list > .slick-track {
            margin-left:0;
        }
        &.slick-initialized {
            &:after {
                display: none;
            }
        }
        .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
            background: #ffffff !important;
        }
        .slick-track {
            display: flex !important;
        }
        .category {
            cursor: pointer;
            img {
                height: 80px;
            }
            h3 {
                font-size: 18px;
            }
            p {
                font-size: 14.5px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .categories {
            margin-top: 50px !important;
            img {
                width: 100px;
            }
        }
    }
}
.contact-page {
    .navbar {
        background-color: transparent !important;
        border-bottom: none;
    }
    .bg-light-dark {
        background-color: $light_dark !important;
    }
    .large-header {
        margin-top: 0 !important;
        background-color: transparent !important;
        .wrap {
            padding-top: 81px;
            @media screen and (max-width: 960px) {
                padding-top: 56px;
            }
        }
    }
    header {
        background: url('/assets/img/contact-page/hero-min.jpg') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

.developments .large-header {
    padding: 160px 0;
    min-height: 600px;
    background: url('/assets/img/developments/hero-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    h1 {
        font-size: 2.5rem;
    }
    @media screen and (max-width: 960px) {
        min-height: 300px;
        padding: 80px 0;
    }
}

.resort-complex-page header {
    background-color: $gray;
}

.country-region-page .large-header {
    padding: 160px 0;
    min-height: 600px;
    background: url('/assets/img/country-region/hero-min.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media screen and (max-width: 960px) {
        padding: 80px 0;
        min-height: 200px;
    }
}

.quick-search-form-input {
    position: relative;
    padding: 0 0 0.35rem 30px !important;
    border: 2px solid #ced4da;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    height: auto;
    width: 100%;
    background-color: transparent;
    &:after {
        position: absolute;
        content: ' ';
        display: block;
        margin: auto 0;
        height: 19px;
        width: 18px;
        top: 0;
        left: 0;
        bottom: 0.35rem;
        background-image: url('/assets/img/search-gray-icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        border-top-left-radius: none;
        border-top-right-radius: none;
    }
    input {
        padding: 0 !important;
        height: auto;
        width: 100%;
        background-color: transparent;
        font-size: 1.25rem;
        &:focus {
            background-color: transparent;
            border-color: #ced4da;
            box-shadow: none;
        }
    }
}
